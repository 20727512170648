import React, { useEffect, useMemo, useState } from 'react'
import { LoaderFunctionArgs } from 'react-router-dom'
import { useDealTenantConfigs } from './hooks'
import { Tenant } from './api/tenants'

export type RetentionPolicy = {
  event_visibility_period: number
}

export type DealTenants = {
  tenants: string[]
}

interface DataTrailsError {
  code: number
  message: string
  details: any
}

export const dealDetailsLoader = async (args: LoaderFunctionArgs): Promise<DealTenants | null> => {
  const dealID = args.params.dealId
  if (args.params.dealId) {
    const response = await fetch(`/session/archivist/internal/v1/tenanciesmgmt/deals/${dealID}`)
    if (!response.ok) {
      const body = (await response.json()) as DataTrailsError
      throw new Error(body.message)
    }

    // Grab the list of tenant IDs for the deal.
    const deal = (await response.json()) as DealTenants
    return deal
  }

  return null
}

export const DealDetails = () => {
  const { configs, loading, error } = useDealTenantConfigs()
  const [dealID, setDealID] = useState<string>('')

  const [estate_total, retained_total] = useMemo(() => {
    let runningRetainedTotal = 0
    let runningEstateTotal = 0
    if (configs.length) {
      for (let i = 0; i < configs.length; i++) {
        // Note: events_within_horizon is an int64 so in JSON its a string.
        if (configs[i].events_within_horizon) {
          runningRetainedTotal += Number(configs[i].events_within_horizon!)
        }

        if (configs[i].total_events) {
          runningEstateTotal += Number(configs[i].total_events!)
        }
      }

      return [runningEstateTotal, runningRetainedTotal]
    }

    return [0, 0]
  }, [configs])

  useEffect(() => {
    if (configs.length) {
      setDealID(configs[0].deal_id)
    }
  }, [configs])

  return (
    <div>
      <h3>Displaying tenants under deal: {dealID}</h3>
      <table>
        <tr>
          <th>Identity</th>
          <th>RetentionPeriod (Months)</th>
          <th>Events in Estate</th>
          <th>Events in Retention Period</th>
        </tr>

        {loading && <p>Loading ...</p>}

        {error && <p>Error loading tenants: {error.message}</p>}

        {!loading && !error && configs.length > 0 && (
          <>
            {configs?.map((tenant) => (
              <tr>
                <td>{tenant.identity}</td>
                <td>{tenant.retention_policy?.event_visibility_period}</td>
                <td>{tenant.total_events}</td>
                <td>{tenant.events_within_horizon}</td>
              </tr>
            ))}

            <tr>
              <td></td>
              <td></td>
              <td>Total: {estate_total}</td>
              <td>Total: {retained_total}</td>
            </tr>
          </>
        )}

        {!loading && !error && configs.length == 0 && (
          <tr>
            <td colSpan={4}>No results found</td>
          </tr>
        )}
      </table>
    </div>
  )
}

export default DealDetails
