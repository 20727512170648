import { useEffect, useState } from 'react'
import { loadTenant, Tenant } from './api/tenants'
import { DealTenants } from './DealDetails'
import { useLoaderData } from 'react-router-dom'

export const useDealTenantConfigs = () => {
  const [configs, setConfigs] = useState<Tenant[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  // Get the IDs for relevant tenants from the route loader
  const tenantsInDeal = useLoaderData() as DealTenants

  const fetchConfigs = async () => {
    try {
      const configPromises = tenantsInDeal.tenants.map((id) => loadTenant(id.split('/')[1]))
      const tenantConfigs = await Promise.all(configPromises)
      setConfigs(tenantConfigs)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (tenantsInDeal?.tenants?.length) {
      fetchConfigs()
    }
    // If there are no tenants in the deal we're not loading anything.
    else setLoading(false)
  }, [tenantsInDeal])

  return { configs, loading, error }
}
