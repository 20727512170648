import React, { useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const TopBar = styled.div`
  margin-bottom: 50px;
  span,
  a {
    float: right;
  }
`

const Deals = () => {
  const [dealID, setDealID] = useState<string>()
  const navigate = useNavigate()

  return (
    <>
      <TopBar>
        <Link reloadDocument={true} to="/logout">
          Logout
        </Link>
      </TopBar>
      <h1>Deal Explorer</h1>
      <div>
        <input
          type="text"
          name="dealID"
          value={dealID}
          onChange={(ev) => setDealID(ev.target.value)}
          placeholder="Enter hubspot deal ID"
        ></input>
        <button onClick={() => navigate(`/deals/${dealID}`)}>Load</button>
      </div>
      <div>
        <Outlet></Outlet>
      </div>
    </>
  )
}

export default Deals
