import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    padding: 0;
    font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    transition: all 0.25s linear;
    font-size: 1rem;
    margin-left: 20px;
  }

  button {
    background: linear-gradient(rgb(6, 169, 232), rgb(0, 137, 200));
    padding: 8px 20px;
    border: none;
    color: #ffffff;
    text-transform: uppercase;
    cursor: pointer;
    margin: 2px;
  }

  button:hover {
    background: linear-gradient(rgb(6, 169, 232), rgb(0, 100, 200));;
  }

  a {
    background: linear-gradient(rgb(6, 169, 232), rgb(0, 137, 200));
    padding: 8px 20px;
    border: none;
    color: #ffffff;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    margin: 2px;
  }

 .spin {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(0, 137, 200, .3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  input {
    padding: 5px;
    margin: 5px;
  }

  label {
    margin: 5px;
    padding-right: 24px;
  }

  span {
    margin: 5px;
  }

  .bad {
    background-color: #F5793A;
  }

  .maybe {
    background-color: #A95AA1;
  }

  .good {
    background-color: #85C0F9;
  }

  .badge {
    
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 20px;
    min-width: 200px;
    max-width: fit-content;
  }

  .loggedOut {
    margin-top: 100px;
    text-align: center;
  }

  table, th, td {
    border: 1px solid;
  }

`
